html {
  position: relative;
  min-width: 320px;
  background-color: $white;
  color: $black;
  line-height: 1.5;
}

body {
  position: relative;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: 'liga', 'clig', 'kern';
  text-rendering: optimizeLegibility;
}

img,
video {
  display: block;
  user-select: none;
}

a {
  position: relative;
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

span,
strong {
  text-decoration: inherit;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  background: $grey2;
}

a,
button,
[role='button'],
label,
select {
  position: relative;
  cursor: pointer;
  transition: box-shadow 200ms ease, color 200ms ease, background-color 200ms ease, opacity 200ms ease;
  // contain: layout;                                      // chrome issues
  // will-change: opacity, box-shadow, background-color;   // performance issues
}

button,
[role='button'],
label,
select {
  user-select: none;
}

option[value='-1'] {
  display: none;
}

::placeholder {
  color: $grey2;
  font-weight: 400;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: 'liga', 'clig', 'kern';
  text-rendering: optimizeLegibility;
}

::selection {
  background-color: rgba($black, 0.999);
  color: $white;
  text-shadow: none;
}
