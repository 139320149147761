.table-simple {
  width: 100%;
  text-align: left;

  th,
  td {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
    font-weight: 400;
  }

  tr {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
  }
}
