.ratio-16-9 {
  @include ratio(16, 9);
}

.ratio-2-3 {
  @include ratio(2, 3);
}

.ratio-8-5 {
  @include ratio(8, 5);
}

.ratio-4-3 {
  @include ratio(4, 3);
}

.ratio-1-1 {
  @include ratio(1, 1);
}

.img-holder {
  position: relative;
  overflow: hidden;
  contain: content;
  width: 100%;
  

  &::before {
    // background: $grey0;
    content: '';
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.lazy-img {
  opacity: 0;
  transition: opacity 200ms ease;
  width: 100%;
  will-change: opacity;

  &.loaded {
    opacity: 1;
  }
}

.lazy-bg {
  @include divfill;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity 1000ms ease;
  will-change: opacity;
  z-index: 0;
}

.overlay-title {
  position: absolute;
  top: 160px;
  right: 0;
  left: 0;
  z-index: 1;
  margin: 0 auto;
  text-decoration: inherit;

  @include breakpoint($S) {
    top: 100px;
  }
}

.overlay-bottom {
  @include break-prop(bottom right left, 20px, 30px);
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  text-decoration: inherit;
  pointer-events: none;

  > * {
    pointer-events: all;
  }
}

.overlay-top {
  @include break-prop(top right left, 20px, 30px);
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  text-decoration: inherit;
  pointer-events: none;
  padding-right: 60px;

  > * {
    pointer-events: all;
  }
}

.overlay-tag {
  @include break-prop(top right, 20px, 30px, 960px);
  @include square(40px);

  @include breakpoint($L) {
    @include square(60px);
    font-size: 14px;
  }
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $black;
  text-decoration: none !important;
  font-size: 12px;
  pointer-events: none;
  user-select: none;

  &.overlay-tag-small {
    @include square(40px);

    @include breakpoint(max-width 960px) {
      display: none;
    }
    top: 10px;
    right: 10px;
    font-size: 12px;
  }
}

.loaded {
  // background-color: $black;
}

.video-holder {
  position: relative;
  contain: content;
  overflow: hidden;
  width: 100%;
  margin: 40px auto;

  &::before {
    content: '';
    display: block;
    padding-bottom: percentage(9 / 16);
  }

  > iframe {
    @include divfill;
  }
}

.video-holder-interview-with-designer{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
