.dealers-wrapper {
  max-height: 80vh;
  height: 700px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-height: none;
    height: auto;
  }
}

.dealers-tabs {
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }

  .tab{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;

    &.active + .tab-subcategories {
      opacity: 1;
    }
  }

  button{
    display: flex;
    align-items: center;

    &.active {
      color: $black;

      .tab-state-indicator:after {
        display: block;
      }
    }

    img {
      margin-left: 5px;
    }
  }

  .tab-subcategories {
    position: relative;
    left: 24px;
    opacity: 0;
    display: flex;
    align-items: center;
    padding: 3px 7px;
    margin-top: 20px;

    button + button {
      margin-left: 20px;
    }
  }
}

.tab-with-subcategories{
  display: flex;
  flex-direction: column;
}

.tab-state-indicator {
  position: relative;
  width: 26px;
  height: 26px;
  margin-right: 10px;
  background-color: #F0F0F0;
  border-radius: 50%;
  border: 1px solid #ccc;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    width: 10px;
    height: 10px;
    background-color: #000;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
}

.map-container {
  flex-grow: 1;
  contain: content;

  @media screen and (max-width: 768px) {
    height: 400px;
  }
}

.dealers-column {
  display: flex;
  flex-shrink: 0;
  height: 100%;
  flex-direction: column;
  width: colw(4);

  @media screen and (max-width: 768px) {
    width: calc(100% - 30px);
    max-height: 300px;
    margin-bottom: 30px;
  }
}

.dealers-search {
  position: relative;
  flex-shrink: 0;
  .text-input {
    color: $grey2;
  }
}

.button__Clear {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 5;
  transform: translateY(-50%);
}

.dealers-list {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 20px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: rgba($black, 0);
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($black, 0);
  }

  &::-webkit-scrollbar-thumb {
    min-height: 10px;
    border-top: 4px solid transparent;
    border-right: 0;
    border-bottom: 4px solid transparent;
    border-left: 16px solid transparent;
    background-color: $black;
    background-clip: padding-box;

    &:hover {
      background-color: $grey3;
    }

    &:active {
      background-color: $grey2;
    }
  }
}

.dealer-item {
  padding: 20px 0;
  border-bottom: 1px solid $black;
  position: relative;
  display: flex;
  align-items: flex-start;

  &:first-of-type {
    padding-top: 0;
  }
}

.dealer-info {
  width: 100%;
  margin-top: 5px;

  h3 {
    font-weight: 700;
    font-size: 1em;
    margin-bottom: 0.7em;
  }

  * {
    margin-bottom: 0;
  }

  .mbs {
    margin-bottom: 0.7em;
  }

  a {
    white-space: nowrap;
  }
}

.dealer-info-title {
  position: relative;
  padding-right: 30px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    right: 0;
    width: 15px;
    height: 9px;
    background: transparent url('../assets/svg/chevron-down.svg') no-repeat scroll center center;
    transition: transform 0.2s linear;
  }

  &.active {
    &:after {
      transform: rotate(180deg);
    }

    & + .dealer-info-data {
      max-height: 500px;
      opacity: 1;
      visibility: visible;
    }
  }

  h3,
  p {
    pointer-events: none;
  }
}

.dealer-info-data {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s linear, max-height 0.2s linear;
}

.dealer-index-col {
  margin-right: 10px;
  width: 30px;
}

.gm-style .gm-style-iw-c {
  border: 1px solid !important;
  border-radius: 0 !important;
  padding: 20px !important;
}

.gm-style .gm-style-iw-t::after{
  border-bottom: 1px solid;
  border-left: 1px solid;
  top: -1px;
}

.dealer-index {
  @include aa;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  // background: $black;
  color: $white;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-window {
  font-size: 13px;
  font-family: Graphik, sans-serif;
  // line-height: (16 / 13);

  p {
    margin-bottom: 0;
  }

  // > * + * {
  //   margin-top: 0.7em;
  // }
}

.gm-style {
  @include aa;
}

// GOOGLE MAPS AUTOCOMPLETE

.pac-container {
  border-radius: 0;
  border-top: none;
  box-shadow: none;
  border: 1px solid $black;
  font-size: 14px;
  font-family: Graphik, sans-serif;
}

.pac-item {
  display: flex;
  align-items: baseline;
  padding: 0.5em $gutter / 2;
  border-top: none;
  font-size: 12px;
  color: $grey2;
}

.pac-item-query {
  font-size: 14px;
}

.pac-icon {
  // margin: 0;
  // margin-right: 10px;
  // width: width('svg/map-marker.svg');
  // height: height('svg/map-marker.svg');
  // align-self: center;
  display: none;
}

// .pac-icon-marker {
//   background-image: svg-load('svg/map-marker.svg', fill="currentColor") !important;
//   background-size: contain !important;
//   background-position: center !important;
// }

.pac-item-selected,
.pac-item-selected:hover {
  background-color: $grey0;
}
