/* Lists & items */

.inline-list {
  display: flex;

  &:not(.no-wrap) {
    flex-wrap: wrap;
  }

  &:not(.grow-reverse) {
    > li {
      &:not(:last-of-type) {
        @include break-prop(margin-right, 10px, 15px);
      }
    }
  }

  &.grow-reverse {
    justify-content: flex-end;

    > li {
      @include break-prop(margin-left, 10px, 15px);
    }
  }
}

.item {
  position: relative;
  // flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  text-align: center;
  // contain: content;
}

.item-list {
  @include breakpoint($S) {
    display: none;
  }
  margin-top: em(10, 16);
}

// .list-alternating {
//   @include breakpoint($M) {
//     flex-flow: row wrap;

//     > .item {
//       &:nth-of-type(1) {
//         order: 2;
//       }

//       &:nth-of-type(2) {
//         order: 3;
//       }

//       &:nth-of-type(3) {
//         order: 6;
//       }

//       &:nth-of-type(4) {
//         order: 1;
//       }

//       &:nth-of-type(5) {
//         order: 4;
//       }

//       &:nth-of-type(6) {
//         order: 5;
//       }
//     }
//   }
//   flex-flow: column nowrap;
// }

.link-all {
  @include breakpoint($M) {
    display: none;
  }
  align-self: flex-end;
  margin: 0 10px;
  text-transform: uppercase;
}

.alternating-title {
  @include break-prop(margin-left margin-right, 10px, 15px);

  @include breakpoint($M) {
    display: none;
  }
  margin-bottom: 20px;
}

.item-product {
  @include col(1, 2);
  @include break-prop(margin-bottom, 30px, 60px);

  @include breakpoint($M) {
    @include col(1, 3);
  }
}

.item-typology {
  @include col(1, 2);
  margin-bottom: 100px;

  @include breakpoint($M) {
    @include col(1, 5);
  }
  flex-shrink: 1;

  svg {
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.collection-navigation-wrapper{
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  .collection-navigation{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 350px;

    a{
      text-decoration: none;
      text-align: center;

      @media screen and (max-width: 400px){
        font-size: 14px;
      }
  
      &.active-collection-tab{
        font-weight: bold;
      }
  
      span{
        transform-origin: center;
      }
    }
  }
  
}

.item-wrapper-dropdown {
  display: none;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 89px;
  height: 100px;
}

.item-dropdown-active {
  display: block;
}

.item-typology-frontpage {
  @include col(1, 3, 10px);
  @include break-prop(margin-bottom, 10px, 30px, 640px);

  @include breakpoint($S) {
    .item-title {
      font-size: 12px;
    }
  }

  @include breakpoint($M) {
    @include col(2, 10, $margin: false);
  }
  flex-shrink: 1;

  svg {
    max-width: 100%;
    height: auto;
  }
}

.item-title-wrapper{
  position: relative;
  max-width: 196px;
  margin: 0 auto;
  cursor: pointer;

  .item-title{
    padding-bottom: 4px;
    font-size: 16px;
    z-index: 11;

    &:hover{
      .underliner{
        transform: scaleX(1);
        transform-origin: center;
        border-bottom: 1px solid #000000;
      }
    }
    
    &.active-list-item-title{
      cursor: default;
      a{
        cursor: default;
      }
      .underliner{
        transform: scaleX(1);
        transform-origin: center;
        border-bottom: 2px solid #000000 !important;
      }
      &:hover{
        .underliner{
          transform: scaleX(1);
          transform-origin: center;
          border-bottom: 2px solid #000000 !important;
        }
      }
    }
  }

  .item-list{
    background-color: #ffffff;
    z-index: 10;
    position: absolute;
    display: none;
    flex-direction: column;
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    a{
      &:first-child{
        margin-top: 15px;
      }
      margin-top: 3px;
      text-decoration: none;
      font-size: 14px;

      &:hover{
        text-decoration: underline;
      }
    }

    &.active-list-item{
      display: flex;
      min-height: 0;
      height: auto;
    }
  }
}

.item-collection {
  @include break-prop(margin-bottom, 30px, 60px);
  @include col(1, 1);

  @include breakpoint(480px) {
    @include col(1, 2);
  }
  position: relative;
  contain: content;
}

// .item-collection-frontpage {
//   @include col(1, 1);
//   position: relative;
//   contain: content;

//   @include break-prop(margin-bottom, 30px, 60px);

//   @include breakpoint($M) {
//     @include col(8);

//     .item-label {
//       display: none;
//     }
//   }
// }

.item-news {
  @include col(1, 1);

  @include breakpoint(480px) {
    @include col(1, 2);
  }

  @include break-prop(margin-bottom, 30px, 60px);

  @include breakpoint($M) {
    @include col(4);
  }

  @include breakpoint($S) {
    .h2 {
      font-size: 16px;
      line-height: (24 / 16);
      font-weight: 400;
    }
  }

  > .u-block {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.news-excerpt {
  @include breakpoint($S) {
    display: none;
  }
  margin-top: 10px;
  margin-bottom: 0;
  text-align: left;
}

.news-date {
  margin-top: auto;
  margin-bottom: 0;
  padding-top: 10px;
  text-align: left;
}

// .item-news-frontpage {
//   @include breakpoint($M) {
//     @include col(6);
//   }

//   @include breakpoint($S) {
//     .h2 {
//       font-size: 16px;
//       line-height: (24 / 16);
//     }

//     &::before {
//       margin-top: 10px;
//       margin-bottom: 20px;
//       height: 1px;
//       background: $black;
//       content: '';
//     }

//     + .item-news-frontpage {
//       &::before {
//         margin-top: 20px;
//       }
//     }

//     &:last-of-type {
//       &::after {
//         margin-top: 20px;
//         margin-bottom: 30px;
//         height: 1px;
//         background: $black;
//         content: '';
//       }
//     }

//     .breaks-cols {
//       @include break-prop(margin-left margin-right, -10px, -15px);
//       display: flex;
//       flex-direction: row;

//       > * {
//         @include col(1, 2);
//         margin-top: 0;
//       }
//     }
//   }

//   @include breakpoint($M) {
//     @include col(4);
//   }
// }

.item-label {
  @include break-prop(margin-bottom, 10px, 15px);

  @include breakpoint($S) {
    display: none;
  }
  position: relative;
  display: table;
  text-align: left;
  text-transform: uppercase;
  line-height: 1;
}

.item-title {
  position: relative;
  margin-top: 10px;
  font-size: 16px;
  line-height: (24 / 16);

  + .item-title {
    margin-top: 0;
  }
}

.variation-title {
  position: relative;
  margin-top: 10px;
  font-size: 16px;
  line-height: (24 / 16);
}

[data-hidden-title] {
  &::before {
    visibility: hidden;
    content: attr(data-hidden-title);
    font-weight: 700;
    font-size: 26px;
    line-height: 1.2;
  }
}

.item-project {
  @include break-prop(margin-bottom, 30px, 60px);
  @include col(1, 1);

  @include breakpoint(480px) {
    @include col(1, 2);
  }

  @include breakpoint($M) {
    @include col(1, 3);
  }
}

.item-designer {
  @include break-prop(margin-bottom, 30px, 60px);
  @include col(1, 1);

  @include breakpoint(480px) {
    @include col(1, 2);
  }
}

.row-list {
  flex: 1 1 auto;

  > li {
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid $black;

    &:last-of-type {
      border-bottom: 1px solid $black;
    }
  }

  &.row-list-spaced {
    > li {
      border-bottom: 0;

      + li {
        margin-top: 60px;
      }
    }
  }
}

.split-row {
  display: flex;
  justify-content: space-between;

  > * + * {
    @include break-prop(margin-left, 15px, 30px);
    text-align: right;
  }
}

.download-list {
  // margin-top: 30px;
  margin-bottom: 30px;
}

.download-item {
  border-top: 1px solid $black;

  &:last-of-type {
    border-bottom: 1px solid $black;
  }

  > a {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    &:hover {
      opacity: 0.6;
    }

    > div {
      &:nth-of-type(1) {
        margin-right: $gutter;
      }

      &:nth-of-type(2) {
        display: flex;
        align-items: center;
        margin-left: auto;
        white-space: nowrap;

        > .icon-box {
          margin-left: 12px;
        }
      }
    }
  }
}

.download-list-collections {
  border-top: 1px solid $black;
}

.item-collections {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $black;
}

.item-collections-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-collections-downloads {
  flex: 1;
  padding-left: 30px;
  min-width: colw(4);

  @include break-prop(padding-left, 15px, 30px);

  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:first-of-type) {
      border-top: 1px solid $black;
      padding-top: 15px;
    }

    &:not(:last-of-type) {
      padding-bottom: 15px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

.item-catalogue {
  @include col(1, 2);
  @include break-prop(margin-top, 30px, 20px);

  @include breakpoint($M) {
    @include col(1, 6);
  }

  img {
    margin-bottom: 10px;
  }
}

.item-catalogue-category {
  text-transform: uppercase;
  text-align: left;
  font-size: 13px;
  font-weight: 700;
}

.item-catalogue-filetype {
  margin-top: 0.5em;
  font-size: 14px;
}

.award-item {
  @include col(1, 2);
  @include break-prop(margin-top, 100px, 80px);

  @include breakpoint($M) {
    @include col(1, 4);
  }

  h2 {
    font-size: 16px;
    margin: 20px 0;
    font-weight: 400;
  }

  p {
    font-size: 13px;
  }

  ul {
    font-size: 13px;

    li {
      padding: 20px 0;
      border-top: 1px solid $black;

      &:last-of-type {
        border-bottom: 1px solid $black;
      }

      img {
        max-width: 210px;
        margin-top: 15px;
      }
    }
  }
}

.awardImageHolder {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 75%;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
// Iconic Award: Innovative interior "Best of Best" in the category Furniture they sent too large image, 
// for logo and important is because the rest of images are styled with nesting so i needed to use !important to override
.awards-inovative-interior{
  max-width: 75px !important;
}

.catalogue-item {
  @include col(1, 2);
  @include break-prop(margin-top, 30px, 20px);

  @include breakpoint($M) {
    @include col(1, 4);
  }

  h2 {
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
  }

  p {
    color: $grey2;
  }
}

.item-featured-frontpage {
  @include col(1, 1);
  margin-bottom: $gutter;

  @include breakpoint($M) {
    &:nth-of-type(1),
    &:nth-of-type(4),
    &:nth-of-type(5) {
      @include col(4, 12);
    }

    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(6) {
      @include col(8, 12);
    }
  }
}
