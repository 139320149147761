.newsletter-form {
  .btn-cta {
    width: 100%;
  }
}

.newsletter-form-tabs-wrapper {
  display: flex;
  width: 100%;
  @include break-prop(margin-left margin-right, 10px, 15px);
  margin-bottom: 30px;

  & > div {
    width: 100%;
  }

  input:checked + label {
    font-weight: 700;

    &:after {
      height: 2px;
    }
  }

  label {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    text-align: center;
    font-size: 16px;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $black;
    }
  }
}