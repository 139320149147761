.material-group {
  border-bottom: 1px solid $black;
}

.material-group-title {
  width: 100%;
  line-height: 1.3;
}

.material-group-title-content {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.material-group-control {
  width: 100%;

  &[aria-expanded='true'] {
    .material-expand-closed {
      display: none;
    }
  }

  &[aria-expanded='false'] {
    .material-expand-open {
      display: none;
    }
  }

  &:not(:disabled) {
    &:hover {
      color: $grey3;
    }
  }

  &:disabled {
    .material-group-count {
      display: none;
    }
  }
}

.material-group-features {
  display: flex;
  align-items: center;
}

.material-group-feature {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  margin-left: 8px;
  background-color: #efeded;
  border-radius: 2px;
  font-size: 10px;
  font-weight: bold;
  color: #787878;

  svg {
    margin-right: 4px;
  }
}

.material-expand-info {
  display: flex;
  margin-left: auto;
}

.group-icon {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.material-list {
  @include break-prop(margin-left margin-right, -5px, -10px);
  display: flex;
  flex-flow: row wrap;

  &:not(.is-expanded) {
    > .material-item {
      &:nth-of-type(n + 7) {
        display: none;
      }
    }
  }
}

.material-item {
  @include col(1, 6, 10px, 20px);
  margin-bottom: 20px;
}

.material-name {
  margin-top: 5px;
  font-size: 13px;
}
