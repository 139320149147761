.cookiebar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  will-change: transform, visibility;
  z-index: 10;
  font-size: 13px;
  line-height: em(16, 13);
  padding-top: 15px;
  padding-bottom: 15px;
  transition: transform 300ms ease, visibility 300ms ease;

  &:not(.visible) {
    transform: translateY(100%);
    visibility: hidden;
  }

  > .container {
    display: flex;
    position: relative;
    z-index: 2;
    align-items: center;
  }

  &::before {
    @include divfill;
    content: '';
    opacity: 0.9;
    background: $white;
    z-index: 0;
  }

  a {
    font-weight: 700;
  }

  p {
    margin-right: $gutter;
  }
}

.btn-close-cookiebar {
  margin-left: auto;
  width: 45px;
  height: 45px;
  flex-shrink: 0;

  > .close {
    margin: auto;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}
