svg {
  display: block;
  flex-shrink: 0;
  max-width: 100%;
  // height: auto;
}

// .svg-fill {
//   fill: currentColor;
//   stroke: none;
// }

// .svg-stroke {
//   width: 100%;
//   height: 100%;

//   path {
//     stroke: currentColor;

//     .item-typology & {
//       @include stroke(15px, 2, 320px, 639px);
//       @include stroke(30px, 4, 640px, colw(12));
//     }

//     .item-typology-frontpage & {
//       @include stroke(10px, 3, 320px, 639px);
//       @include stroke(30px, 5, 640px, colw(10));
//     }

//     .menu-item & {
//       @include stroke(10px, 3, 320px, 639px);
//       @include stroke(30px, 6, 640px, colw(12));
//     }
//   }
// }

.icon-box {
  @include square(40px);
  display: flex;
  margin-top: -.5em;
  margin-bottom: -.5em;
  background-color: $black;
  color: $white;

  > svg {
    margin: auto;
  }
}
