.video-landing {
  position: relative;
  contain: content;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  height: 100%;

  &::before{
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }

  .play-circle {
    @include breakpoint($S) {
      display: none;
    }
  }
}

.video-landing,
.product-video {
  .play-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    transform: translateX(-50%) translateY(-50%);
    color: $white;
    pointer-events: none;
    opacity: 0.5;
    will-change: opacity;
    transition: opacity 120ms ease;
  }
}

.video-landing-inner {
  @include divfill;
  padding-top: 60px;
}

.video-fs,
.video-player {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;

  &:not(.is-visible) {
    opacity: 0;
    visibility: hidden;
  }
}

.video-header-image,
.video-header-mobile-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 400;
  cursor: pointer;
}

.video-header-image-hidden,
.video-header-mobile-image-hidden {
  display: none;
}

#yt-player {
  @include divfill;
}

.img-overlay-wrap {
  position: relative;

  .play-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    transform: translateX(-50%) translateY(-50%);
    will-change: opacity;
    transition: opacity 120ms ease;
    color: $white;
  }
}

a:hover .play-circle {
  opacity: 1;
}
