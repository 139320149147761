.base-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  background: $white;
  opacity: 0;
  transition: visibility 300ms ease, opacity 300ms ease;
  will-change: opacity, visibility;
  contain: strict;
  display: flex;
  flex-direction: column;

  &.is-open {
    visibility: visible;
    opacity: 1;
  }

  &.black-modal {
    @include aa;
    background: $black;
    color: $white;
  }
}

.black-modal {
  .modal-content {
    overflow: hidden;
  }
}

.modal-header {
  background-color: inherit;
  display: flex;
  padding: 10px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
}

.close-modal {
  margin-left: auto;
  width: 44px;
  height: 44px;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }

  > svg {
    margin: auto;
  }
}

.modal-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;

  > img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
    align-self: flex-start;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

.modal-center {
  margin: auto;
  width: 100%;
}
