.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black;
  z-index: 450;
  color: $white;
  will-change: transform;
  display: flex;
  flex-direction: column;
  transition: transform 400ms ease, visibility 400ms ease;

  @include breakpoint($M) {
    display: none;
  }

  &:not(.is-open) {
    transform: translateY(-100%);
    visibility: hidden;
  }

  &:not(.is-interactable) {
    pointer-events: none;
  }

  .accordion-control {
    will-change: opacity;

    &[aria-expanded='true'] {
      opacity: 0.7;
    }
  }

  .accordion-content {
    .mobile-menu-item {
      &:last-of-type {
        margin-bottom: 18px;
      }
    }
  }
}

.mobile-menu-inner {
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
  flex: 0 1 auto;
  width: 100%;
}

.mobile-menu-item {
  padding: 18px 0;
  width: 100%;
  display: block;
  text-align: left;
  border-top: 1px solid $white;

  .accordion-content & {
    border-top: none;
    padding: 0.5em 0;
  }
}

.mobile-lang-list {
  display: flex;
  align-items: center;

  > li {
    + li {
      margin-left: $gutter;
    }
  }
}

.mobile-menu-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 44px;
  height: 44px;

  > svg {
    margin: auto;
  }
}
