.container {
  @include break-prop(padding-left padding-right, 10px, 15px);
  margin-right: auto;
  margin-left: auto;
  max-width: colw(12);
  width: 100%;
}

.grid {
  @include break-prop(margin-left margin-right, -10px, -15px);
  display: flex;
  flex-wrap: wrap;
}

.grid-centered {
  justify-content: center;
}

// .grid-breaks-single {
//   @include breakpoint($S) {
//     // flex-flow: column nowrap;
//   }
// }

.grid-item {
  @include break-prop(margin-left margin-right, 10px, 15px);
}

@for $i from 1 through $ncol {
  .col-#{$i} {
    @include col($ncol / $i);
  }
}

.max-10 {
  max-width: colw(10);
  width: 100%;
}

.max-8 {
  max-width: colw(8);
  width: 100%;
}

.max-6 {
  max-width: colw(6);
  width: 100%;
}

.max-2 {
  max-width: colw(2);
  width: 100%;
}
