.media {
  display: flex;
}

.media-img {
  flex: 1 0 auto;
  max-width: 166px;
  width: 100%;
  // display: flex;
  // align-content: flex-start;

  > img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}

.media-body {
  @include break-prop(margin-left, 15px, 30px);
  flex: 1 1 auto;
  position: relative;
}

.media-center {
  align-items: center;
}
