.article-single-out{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  line-height: 1.43;
  color: $black;
  max-width: 693px;
  margin: 0 auto;

  &::first-letter{
    text-transform: uppercase;
  }

  &::before,
  &::after{
    display: block;
    content: ' ';
    width: 29px;
    height: 1px;
    border: solid 1px $black;
  }

  &::before{
    margin: 60px 0 30px 0;
  }

  &::after{
    margin: 30px 0 60px 0;
  }
}

.news-article-images-sections{
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .news-article-image-section{
    width: calc(50% - 15px);
    display: flex;
    flex-direction: column;
    margin-bottom: 41px;
    img{
      width: 100%;
      height: auto;
      margin-bottom: 13px;
    }

    p{
      font-size: 14px;
      line-height: 1.57;
      color: $black;
    }

    &:nth-child(odd){
      margin-right: 15px;
    }

    &:nth-child(even){
      margin-left: 15px;
    }

    &:last-child,
    &:nth-last-child(2):nth-child(odd){
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}