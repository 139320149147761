a:hover,
.anchor:hover {
  .underliner {
    transform: scaleX(1);
  }
}

.more-link {
  margin-top: 20px;
}

.custom-hitbox {
  pointer-events: none;

  .underliner {
    ~ span {
      pointer-events: all;
      padding-top: 2px;
      padding-bottom: 2px;
      z-index: 1;
      position: relative;
    }
  }
}

.underliner {
  position: absolute;
  right: 0;
  left: 0;
  transition: transform 200ms ease;
  transform: scaleX(0);
  transform-origin: left;

  .u-left-copy & {
    transform-origin: left !important;
  }

  .item-title &,
  .menu-item-title &,
  .menu-item-all & {
    transform-origin: center;
  }
}

.underliner-copy {
  display: inline;
  border-bottom: 1px solid $black;
  color: transparent;
  pointer-events: none !important;
  speak: none;
  margin-left: -1px;        // safari new line break fix

  &::after {
    speak: none;
    content: attr(data-copy);
  }

  .section-designer & {
    border-color: $white;
  }

  .h1 &,
  .h2 &,
  .h3 &,
  .h4 &,
  .fp-cell-title & {
    border-width: 2px;
  }
}

.link-icon {
  display: flex;
  align-items: center;
  text-transform: uppercase;

  .underliner {
    right: 30px;
  }

  > .svg {
    flex-shrink: 0;
    margin-left: 10px;
  }
}

button[data-url]:not(.anchor) {
  &:hover {
    opacity: 0.7;
  }
}
