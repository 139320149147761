// Main menu

.menu-btn-container {
  position: absolute;
  z-index: 20;
  pointer-events: none;

  @include breakpoint($S) {
    top: 0;
    left: 0;
    right: 0;
  }

  @include breakpoint($M) {
    position: fixed;
    width: 320px;
    top: 50%;
    right: 0;
    transform-origin: bottom right;
    transform: rotate(-90deg) translateX(210px);
    will-change: transform;
  }
}

.btn-open-menu {
  background: $white;
  line-height: 1;
  margin: auto;
  pointer-events: all;
  height: 44px;
  font-weight: bold;

  @include breakpoint($S) {
    font-size: 10px;
    margin-left: auto;
    margin-right: 80px;
  }

  > span {
    transition: opacity 200ms ease;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 5px;
    will-change: opacity;
  }

  &:hover {
    > span {
      opacity: 0.7;
    }
  }
}

.menu-listing {
  margin: auto;
  max-width: colw(12);
  width: 100%;

  > ul {
    display: flex;
    flex-flow: row wrap;
  }

  > h4 {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.menu-item {
  @include breakpoint(640px) {
    margin-right: 15px;
    margin-top: 30px;
    margin-left: 15px;
    width: calc(#{percentage(2 / 12)} - 30px);
  }
  margin-right: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
  width: calc(#{percentage(1 / 3)} - 10px);
  text-align: center;
  font-size: 13px;
  line-height: (16 / 13);
}

.menu-item-title {
  position: relative;
  display: table;
  margin-top: em(7, 13);
  margin-right: auto;
  margin-left: auto;
  table-layout: fixed;
}
