.u-mt-s {
  @include break-prop(margin-top, 20px!important, 30px!important);

  .u-mt-n & {
    @include break-prop(margin-top, 20px!important, -30px!important);
    position: relative;
  }
}

.u-mb-s {
  @include break-prop(margin-bottom, 20px!important, 30px!important);
}

.u-mb-l {
  @include break-prop(margin-bottom, 30px!important, 60px!important);
}

.u-mt-l {
  @include break-prop(margin-top, 30px!important, 60px!important);
}

.u-mb-0 {
  margin-bottom: 0 !important;
}

.u-uppercase {
  text-transform: uppercase !important;
}

.u-no-case {
  text-transform: none !important;
}

.u-lowercase {
  text-transform: lowercase !important;
}

.u-underline {
  text-decoration: underline !important;
}

.u-center-container {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.u-center-content {
  margin: auto;
}

.u-is-none {
  display: none;
}

.u-is-hidden {
  visibility: hidden;
}

.u-red {
  color: $red;
}

.u-white-copy {
  color: $white;

  .underliner-copy {
    border-color: $white;
  }
}

.u-block {
  position: relative;
  display: block;
  width: 100%;
}

.u-center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.u-center-copy {
  margin-right: auto !important;
  margin-left: auto !important;
  text-align: center !important;
}

.u-right-copy {
  margin-right: 0 !important;
  text-align: right !important;
}

.u-left-copy {
  margin-left: 0 !important;
  text-align: left !important;
}

.u-light-copy {
  font-weight: 400 !important;
}

.u-strong-copy {
  font-weight: 700 !important;
}

.u-sr-only {
  @include sr-only;
}

.u-divfill {
  @include divfill;
}

.u-release {
  margin-right: calc(-50vw + 50%);
  margin-left: calc(-50vw + 50%);
}
