/* Unless said otherwise, logic is min-width */

/* Hard column width */
@function colw($n, $gutter: $gutter) {
  @return $n * ($col + $gutter);
}

/* Strip units */
@function strip($value) {
  @return $value / ($value * 0 + 1);
}

/* Clamped property lerp mixin */
@mixin clamp($properties, $min, $max, $min-vw: 480px, $max-vw: colw(12)) {
  // lower value
  @each $property in $properties {
    #{$property}: $min;
  }

  // lerp
  @include breakpoint($min-vw $max-vw) {
    @each $property in $properties {
      #{$property}: calc(#{strip($max - $min) / strip($max-vw - $min-vw)} * (100vw - #{$min-vw}) + #{$min});
    }
  }

  // upper value
  @include breakpoint($max-vw) {
    @each $property in $properties {
      #{$property}: $max;
    }
  }
}

/* Split props on breakpoint */
@mixin break-prop($properties, $value-small, $value-large, $breakpoint: 640px) {
  @each $property in $properties {
    #{$property}: $value-small;
  }

  @include breakpoint($breakpoint) {
    @each $property in $properties {
      #{$property}: $value-large;
    }
  }
}

/* Columns mixin */
/* stylelint-disable */
@mixin col($columns, $context: $ncol, $margin-small: 20px, $margin-large: 30px, $margin: true) {
  $width: 100% / $context * $columns;

  @include break-prop(width, calc(#{$width} - #{$margin-small}), calc(#{$width} - #{$margin-large}));

  @if $margin == false {
  }

  @else if $margin == auto {
    margin-right: auto;
    margin-left: auto;
  }

  @else {
    @include break-prop(margin-left margin-right, $margin-small / 2, $margin-large / 2);
  }
}
/* stylelint-enable */

@mixin divfill($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Screen reader only */
@mixin sr-only {
  & {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;
    white-space: nowrap !important;
    clip-path: inset(100%) !important;
    clip: rect(0 0 0 0) !important;
    overflow: hidden !important;
    contain: strict !important;
  }
}

@mixin aa {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin no-aa {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

@mixin ratio($x, $y) {
  position: relative;
  display: block;

  &::before {
    padding-top: percentage($y / $x);
    content: '';
  }
}

/* Returns value in ems */
@function em($value, $context: 16) {
  @return $value / $context * 1em;
}

@mixin square($x) {
  width: $x;
  height: $x;
}

@mixin hover {
  .no-touchevents &:hover {
    @content;
  }
}

@mixin active {
  .touchevents &:active,
  .no-touchevents &:active {
    @content;
  }
}

@mixin focus {
  .no-touchevents &:focus {
    @content;
  }
}
