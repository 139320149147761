.accordion-group {
  border-top: 1px solid $black;

  &:last-of-type {
    border-bottom: 1px solid $black;
  }
}

.accordion-control {
  padding: 15px 0;
  width: 100%;
  text-align: left;
  font-size: 16px;
  line-height: (24 / 16);
  transition: color 200ms ease;
  user-select: auto;

  &:hover {
    color: $grey3;
  }

  &[aria-expanded='false'] {
    .icon-collapse {
      display: none;
    }
  }

  &[aria-expanded='true'] {
    .icon-expand {
      display: none;
    }
  }
}

.accordion-icon {
  flex-shrink: 0;
  margin-left: 15px;
}

.accordion-control-inner {
  display: flex;
  align-items: center;
}

.accordion-control-content {
  margin-right: auto;
}

.accordion-content {
  overflow: hidden;
  contain: content;

  &[aria-hidden='true'] {
    height: 0;
  }
}
