.material-filter {
  border-bottom: 1px solid $black;
}

.menu-button {
  text-align: left;

  .svg {
    flex-shrink: 0;
    margin-left: 8px;
  }

  &[aria-expanded='true'] {
    color: $grey2;

    .svg {
      transform: scaleY(-1);
    }
  }

  &:hover {
    color: $grey3;
  }

  &.is-open {
    color: $grey2;

    .svg {
      transform: scaleY(-1);
    }
  }
}

.toolbar {
  display: flex;
  border-top: 1px solid $black;

  > .menu-button {
    margin-left: 15px;
  }

  @include breakpoint($S) {
    flex-wrap: wrap;
  }
}

.materials {
  margin-right: auto;

  > div {
    display: flex;
  }
}

.collections {
  margin-right: auto;
  margin-left: auto;

  > div {
    display: flex;
  }

  @include breakpoint($S) {
    order: 2;
    width: 100%;
  }
}

.material-filter-item-title {
  padding-top: 15px;
  padding-bottom: 15px;
  color: $grey2;
}

.material-filter-item {
  display: flex;

  + .material-filter-item {
    margin-left: 15px;
  }

  &:hover {
    .material-filter-item-title {
      color: $grey3;
    }
  }
}

.material-radio,
.collection-radio {
  @include sr-only;

  &:checked {
    + .material-filter-item-title {
      color: $black;
    }
  }
}

.menu-button-inner {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  padding-right: 0;
  white-space: nowrap;
}

.menu-colors {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;

  &:not(.is-open) {
    display: none;
  }
}

.menu-colors-text-description{
  padding-left: 5px;
}

.material-container-no-value{
  margin-top: 50px;
}

.material-filter-notice {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1.5;
}

.menu-colors-grid {
  // @include break-prop(margin-left margin-right, -5px, -10px);
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  flex-flow: row wrap;
  // justify-content: space-between;
}

.color-box-inner {
  @include ratio(1, 1);
  // box-shadow: inset 0 0 0 1px $grey1;
  transition: box-shadow 200ms ease;

  > span {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 5px;
    font-size: 13px;
  }
}

.color-box {
  margin-left: 5px;
  margin-right: 5px;
  width: 54px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 18px;

  &:first-of-type {
    .color-box-inner {
      > span {
        box-shadow: inset 0 0 0 1px $grey1;
      }
    }
  }

  &:hover {
    > .color-box-inner {
      box-shadow: inset 0 0 0 1px $grey1;
    }
  }
}

.color-radio {
  @include sr-only;

  &:checked {
    + .color-box-inner {
      box-shadow: inset 0 0 0 1px $black;
    }
  }
}

.btn-clear-colors {
  align-self: flex-end;
  height: 40px;
  text-transform: uppercase;
  font-size: 13px;

  &:disabled {
    color: $grey2;
  }

  &:not(:disabled) {
    &:hover {
      color: $grey3;
    }

    &:active {
      color: $grey2;
    }
  }
}
