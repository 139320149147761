.contact-form {
  margin: 20px 0;
}

.contact-item {
  @include col(1, 1);

  @include breakpoint($M) {
    @include col(1, 2);
  }
  line-height: 1.88;
  padding-top: 20px;
  margin-bottom: 20px;

  h2,
  h3 {
    font-weight: 700;
    font-size: 16px;
  }

  a {
    vertical-align: bottom;
  }
}

.contact-lead {
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 700;
}

.c-box {
  width: 2em;
  display: inline-block;
}

.thank-you-header{
  .thank-you-title{
    margin-bottom: 60px;
  }

  .btn{
    margin-top: 80px;
  }
}

.contact-form-second-step {
  &.hidden {
    display: none;
  }
}