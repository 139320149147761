.form {
  counter-reset: section;
}

.form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  & + .form-copy,
  & + .form-copy-side-margins {
    margin-top: 0;
  }

  &:not(.single) {
    @include break-prop(margin-left margin-right, 10px, 15px);
    @include col(1, 1);
  }

  &.half {
    @include break-prop(margin-left margin-right, 10px, 15px);

    @include breakpoint($M) {
      @include col(1, 2);
    }
  }

  &.required {
    .label {
      &::after {
        display: inline-flex;
        margin-left: 1 / 3 * 1em;
        content: '*';
        font-size: 1.4em;
        line-height: 1rem;
        color: $red;
      }
    }
  }

  &.error,
  &.error-format {
    input,
    select,
    textarea,
    .checkbox {
      background-color: mix($red, $white, 15%);
    }
  }

  &.hiddenFormField {
    display: none;
  }
}

.error-msg,
.front-error-msg,
.front-format-error-msg {
  font-size: 13px;
  color: $red;
  margin-top: 0.5em;
}

.front-error-msg {
  display: none;

  .error & {
    display: block;
  }
}

.front-format-error-msg {
  display: none;

  .error-format & {
    display: block;
  }
}

.label {
  position: relative;
  margin-bottom: 10px;

  > small {
    display: table;
    font-size: 16px;
  }
}

.select {
  padding-right: 32px;
  padding-left: 16px;
  width: 100%;
  height: 50px;
  // border: 1px solid $black;
  background-color: $grey0;
  background-image: svg-load('svg/chevron-down.svg', fill='currentColor');
  background-position: center right 12px;
  background-size: 12px;
}

.form-copy {
  margin: 20px 0;
}

.form-copy-side-margins {
  margin: 20px 15px;
}

.form-disclaimer {
  font-size: 12px;
}

.text-input {
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  height: 50px;
  // border: 1px solid $black;
  background-color: $grey0;
}

.placeholder-label {
  &::placeholder {
    color: inherit;
  }
}

.inline-inputs {
  @include break-prop(margin-left margin-right, -5px, -10px);
  display: flex;
  width: auto;

  &.half {
    max-width: colw(4);
  }

  > input {
    @include break-prop(margin-left margin-right, 5px, 10px);
  }
}

.textarea {
  padding: 12px 16px;
  width: 100%;
  background-color: $grey0;
}

.checkbox-label {
  display: flex;
  align-items: center;
}

.checkbox {
  @include break-prop(margin-right, 10px, 15px);
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  // border: 1px solid $black;
  background-color: $grey0;
  background-position: 50% 50%;
}

.checkbox-input {
  @include sr-only;

  &:checked {
    + .checkbox {
      background-image: svg-load('svg/check.svg', fill='currentColor');
    }
  }
}

.checkbox-label-content {
  a {
    pointer-events: all;
  }
}

.form-step {
  margin-top: 40px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid $black;
  counter-increment: section;
  text-transform: uppercase;
  font-size: 16px;

  &::before {
    display: inline;
    content: counter(section) '. '; /* Display the counter */
  }
}

.file-input-msg {
  display: table;
  margin-top: 10px;
}

.file-input {
  @include divfill;
  opacity: 0;
  z-index: 1;
  pointer-events: all;
}

.file-input-label {
  width: 100%;

  &:hover {
    .btn {
      background-color: $grey3;
      text-decoration: none !important;
    }
  }

  &:active {
    .btn {
      background-color: $grey2;
    }
  }
}

.file-input-row {
  position: relative;
  // pointer-events: all;

  .btn {
    //min-width: 227px;
    width: 50%;
    // &:hover {
    //     background-color: $grey3;
    //     text-decoration: none !important;
    // }
  }

  .text-input {
    width: 50%;
  }
}

.uploadedFiles {
  width: 100%;
  @include breakpoint($M) {
    width: 50%;
  }
}

.filename  {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $grey2;
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;

  &.uploaded {
    color: $black;
  }
}

.filename--Nofile.is-hidden {
  display: none;
}

#careerLabel {
  .file-input {
    pointer-events: none;
  }
  .file-input:last-of-type {
    pointer-events: all;
  }
}
  

.filename__Close {
  background-image: svg-load('svg/close.svg', fill='black');
  width: 30px;
  height: 30px;
  background-position: center center;
  cursor: pointer;
}

.col-pull-right {
  @include col(1, 1);
  margin-bottom: 30px;

  @include breakpoint($M) {
    @include col(1, 2);
    margin-left: auto !important;
  }
}

.register {
  position: relative;

  > .container {
    > .h2 {
      text-align: center;
    }
  }
}

.register-cards {
  position: relative;
  // background-color: $black;
  padding-top: 200px;
  padding-bottom: 200px;
  overflow: hidden;
}

.register-card {
  @include col(1, 1);
  margin-bottom: $gutter;

  @include breakpoint($M) {
    @include col(1, 3);
  }
  background: $white;
  padding: $gutter;
  text-align: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
  justify-content: center;

  > .h2 {
    text-align: center;
    margin-bottom: 1em;
  }

  > ul {
    margin-bottom: 26px;

    > li {
      margin-bottom: 0.2em;
    }
  }

  > .btn {
    margin-top: auto;
  }
}
