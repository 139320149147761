.careerLanding--new {

  h4{
    &::first-letter{
      text-transform: uppercase;
    }
  }

  h4:first-child {
    margin-top: 0px;
  }

  p {
    margin-top: 20px;
    &::first-letter{
      text-transform: uppercase;
    }
  }
}

.positionsList {
  display: flex;
  justify-content: space-around;
  margin: 60px auto 120px;  

  li {
    &:last-child {
      svg {
        max-width: 55px;
        max-height: 55px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    

    li {
      margin-top: 30px;

      &:first-child {
        margin-top: 0px;
      }
    }
  }
}

.positionList__txt {
  text-align: center;

  p {
    margin-top: 0px;
    text-align: center;
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: -2px;
      background-color: black;
      left: 0;
      transform: scaleX(0);
      transition: transform 250ms, opacity 250ms;
      opacity: 0;
    }
  }
}

.positionsList a:hover {
  p:after {
    opacity: 1;
    transform: scaleX(1);
  }

  span {
    background-color: black;
    color: white;
  }
}

.positionList__Box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 166px;
  height: 166px;

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  svg {
    max-width: 65px;
    max-height: 65px;
  }

  span {
    width: 38px;
    height: 38px;
    border: 1px solid black;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    font-weight: 400;
    border-radius: 50%;
    transition: background-color 150ms, color 150ms;

    @media screen and (max-width: 768px) {
      top: -10px;
      right: -10px;
      width: 30px;
      height: 30px;
      font-size: 12px;
    }
  }
}

.careerGallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1186px;
  margin: -30px auto 0;
  box-sizing: border-box;
  padding: 0 20px;

  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
}

.careerGallery__Box {
  width: calc(50% - 15px);
  margin-top: 30px;
  position: relative;
  padding-bottom: 33%;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    padding-bottom: 75%;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}


// careerCat

.title-header--careerCat {
  max-width: 1176px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;

  p {
    margin-top: 20px !important;
  }

  a {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    transition: transform 150ms;
    backface-visibility: hidden;

    &:hover {
      transform: translate(-5px, -50%) rotate(-90deg);
    }
  }
}

.careerCatContainer {
  h2 {
    font-weight: 400;
    margin-top: 40px;

    &:first-child {
      margin-top: 0px;
    }
  }

  .form-item--new {
    margin-bottom: 0px;
  }

  .grid--new {
    flex-wrap: wrap;
    padding: 0 20px;
    justify-content: space-between;

    .form-item {
      width: calc(50% - 15px);
      margin-left: 0;
      margin-right: 0;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.h2--careerFormHeading {
  margin-bottom: 20px;
}

.form-copy--new {
  margin-bottom: 0px !important;
}


.careerPositions {
  li {

    &:last-child {
      a {
        border-bottom: 1px solid black;
      }
    }
  }

  a {
    font-size: 16px;
    font-weight: 600;
    padding: 15px 0;
    display: block;
    border-top: 1px solid black;
    transition: color 150ms;

    &:hover {
      color: #8c8c8c;
    }
  }
}



.h2--positions {
  font-weight: 400;
  text-align: center;
  margin-top: 90px;
}

.container--carCat {
  .positionsList {
    margin-bottom: 0px;
  }
}

.careerSlide {
  max-width: 900px;
  width: 100%;
  min-height: 600px;

  @media screen and (max-width: 860px) {
    max-width: 500px;
    min-height: 334px;
  }

  @media screen and (max-width: 560px) {
    max-width: auto;
    width: 68%;
    min-height: 250px;
  }

  @media screen and (max-width: 560px) {
    min-height: 210px;
  }
}

.careerSlide__buttonBox {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: opacity 350ms;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(white, 0.6);
    z-index: 12;
    transition: background-color 200ms;
  }
  

  button {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: white;
    // border-radius: 50%;
    font-size: 0px;
    z-index: 16;
    transition: transform 200ms;

    &:before {
      content: '';
      background-image: url('../assets/svg/play-circle-white.svg');
      background-size: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;
    }

    &:hover {
      transform: scale(0.95);
    }

    @media screen and (max-width: 768px) {
      width: 70px;
      height: 70px;
    }
  }

  
}

.careerSlide__imgBox {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  left: 15px;
  overflow: hidden;
  z-index: 1;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
  }

  @media screen and (max-width: 560px) {
    right: 5px;
    left: 5px;
  }
}

// video slider
.careerVideoSlider {  
  margin: 60px 0 30px;

  iframe {
    padding: 0 15px;
    position: relative;
    z-index: 1 !important;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @media screen and (max-width: 560px) {
      padding: 0 5px;
    }
  }

  button {
    opacity: 0;
    transition: opacity 200ms, transform 200ms;
  }

  .is-selected {
    .careerSlide__buttonBox:after {
      background-color: rgba(white, 0);
    }

    button {
      opacity: 1;
    }
  }

  .active--video {
    .careerSlide__buttonBox {
      opacity: 0;
      pointer-events: none;
    }
  }


}


.sliderCounter {
  display: flex;
  justify-content: center;

  span {
    font-size: 16px;
  }
}

.sliderCounter__current {
  &:after {
    content: '/';
    display: inline-block;
    margin: 0 5px;
  }
}

.career-apply-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  text-align: center;
}