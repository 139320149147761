.spacing-large {
  @include clamp(margin-top, 90px, 120px);
}

.spacing-small {
  @include clamp(margin-top, 30px, 60px);
}

.page-main {
  position: relative;
  min-width: 320px;
}

.title-header {
  @include clamp(margin-top margin-bottom, 40px, 90px, 480px, colw(8));

  .img-holder & {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
}

.projects-image-container{
  position: relative;
  margin-bottom: 44px;
  display: flex;
  flex-direction: column;

  .projects-collections-text{
    position: absolute;
    bottom: 34px;
    left: 36px;
    font-size: 26px;
    line-height: 1.31;
    color: #000000;

    @media screen and (max-width: 560px) {
      position: relative;
      bottom: auto;
      left: auto;
      font-size: 19px;
      margin-top: 10px;
    }
  }

  .projects-designers-text-container{
    display: flex;
    margin-top: 32px;
    align-items: center;
    align-self: flex-end;

    svg{
      margin-left: 18px;
    }
  }

  .projects-designers-text{
    font-size: 13px;
    font-weight: 600;
    line-height: 1.85;
    text-transform: uppercase;
    color: #000000;
  }
}

.cta-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
}

.more-wrap {
  display: table;
  width: 100%;
}

.more-marker {
  display: table;
  width: 100%;
  // height: 3px;
  // background: pink;
}

.section-designer {
  @include clamp(margin-top, 90px, 120px);
  overflow: hidden;
  padding-top: 90px;
  padding-bottom: 90px;
  background: $black;
  color: $white;
  display: none;

  @include aa;

  .grid {
    justify-content: space-between;
  }

  ::selection {
    background-color: rgba($white, 0.99);
    color: $black;
    text-shadow: none;
  }
}

.section-designer-copy {
  max-width: colw(6);
  width: 100%;

  @include breakpoint($M) {
    margin-left: auto;
  }

  > p {
    margin-top: 1em;
    margin-bottom: 0;
  }

  .more-link {
    margin-left: auto;
    display: table;
  }

  .underliner::before {
    box-shadow: 0 1px 0 0 $white;
  }
}

.fullscreen {
  width: 100%;
  position: relative;
  overflow: hidden;
  contain: content;

  @include breakpoint($S) {
    // max-height: 640px;

    .lazy-bg {
      @include ratio(4, 3);
      position: relative;
      width: 100%;
    }

    .overlay-title {
      position: relative;
      top: auto;
      margin-top: 0.5em;
      text-align: left;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @include breakpoint($M) {
    min-height: 420px;
    height: 100vh;
    margin-bottom: $gutter;
  }
}

.fullscreen-image {
  @include breakpoint($M) {
    min-height: 500px;
    margin-bottom: $gutter;
  }

  img {
    width: 100%;
  }

  @include breakpoint($S) {
    .overlay-title {
      position: relative;
      top: auto;
      margin-top: 0.5em;
      text-align: left;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.cad-sign-in {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.article-gallery:not(s) {
  margin-top: 60px;
  margin-bottom: 60px;

  > .img-holder {
    @include col(1, 2);
    @include break-prop(margin-top, 20px, 30px);

    @media screen and (max-width: 768px) {
      @include col(12);
    }

    &:only-of-type{
      @include col(12);
    }
  }
}

.outletMobileImage {
  display: none;
  max-width: 100%;
  height: auto;
  padding: 0 10px;
  box-sizing: border-box;
}

.outletDesktopImage {
  max-width: 100%;
  height: auto;
  padding: 0 10px;
  box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
  .outletMobileImage {
    display: block;
  }

  .outletDesktopImage {
    display: none;
  }
}
