.rich-copy {
  line-height: 1.875;
  font-size: 16px;
  margin-top: 1em;

  h1:not([class]) {
    @include clamp(font-size, 26px, 40px, 480px, colw(8));
    text-align: left;
    line-height: 1.2;
    // font-weight: 300;
  }

  h2:not([class]) {
    @include clamp(font-size, 15px, 15px, 480px, colw(8));
    text-align: left;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    line-height: 2;

  }

  h3:not([class]) {
    @include clamp(font-size, 15px, 15px, 480px, colw(8));
    text-align: left;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    line-height: 2;

  }

  a:not([class]) {
    text-decoration: underline;
    word-break: break-all;
  }

  ul:not([class]) {
    margin-top: 30px;

    li:not([class]) {
      position: relative;
      padding-left: 24px;

      &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        background: currentColor;
        left: 0;
        top: 0.75em;
        border-radius: 50%;
      }
    }
  }

  > * + * {
    margin-top: 1.5em;
  }

  > *:not(.article-gallery) {
    max-width: colw(8);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #000000;

    &::first-letter{
      text-transform: uppercase;
    }
  }

  >div{
    max-width: none !important;
  }

  blockquote{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.43;
    color: $black;
    max-width: 693px;
    margin: 0 auto;

    &::first-letter{
      text-transform: uppercase;
    }
  
  
    &::before,
    &::after{
      display: block;
      content: ' ';
      width: 29px;
      height: 1px;
      background-color: $black;
    }
  
    &::before{
      margin: 60px 0 30px 0;
    }
  
    &::after{
      margin: 30px 0 60px 0;
    }
  }

  .img-holder {
    position: relative;
    overflow: visible;
    contain: none;
    width: 100%;
    height: 100%;
  
  
    &::before {
      // background: $grey0;
      content: none;
    }

    figure{
      word-break: break-word;  
      font-size: 14px;
      line-height: 1.57;
      color: $black;    
      
      figcaption{
        margin-top: 11px;
        
        &::first-letter{
          text-transform: uppercase;
        }

      }
    }
  }

  .article-gallery-last{ 
    .img-holder {
      position: relative;
      overflow: hidden;
      contain: content;
      width: 100%;

      @include col(1, 2);
      @include break-prop(margin-top, 20px, 30px);
  
      @media screen and (max-width: 768px) {
        @include col(12);
      }
  
      &:only-of-type{
        @include col(12);
      }
      
    
      &::before {
        // background: $grey0;
        content: '';
      }
    
      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .article-gallery {
    margin-top: 7px;
    margin-bottom: 0;
  }
}

.poster-image{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 101;
  cursor: pointer;
}

.playVideoButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  z-index: 102;
  height: 120px;
  width: 120px;
  cursor: pointer;

  &::before{
    content: '';
    background-image: url('../assets/svg/play-circle-white.svg');
    background-size: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
  }
}

.hideOverlay{
  display: none;
}

.article-lead {
  max-width: colw(8);
  margin-left: auto;
  margin-right: auto;
  /* font-weight: 700;
  width: 100%; */
  margin-top: 80px;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.39;
  color: $black;

  &::first-letter{
    text-transform: uppercase;
  }

}

.article-date {
  max-width: colw(8);
  width: 100%;
  margin: 42px auto 73px;

}

.article-product {
  @include col(1, 2);

  h3 {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    margin-top: 10px;
  }
}

.termsPage .rich-copy {
  h2 {
    @include clamp(font-size, 22px, 26px, 480px, colw(8));
  }
}