.homepageContent__Wrapper {
  max-width: 1186px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: 1441px) {
    max-width: none;
    padding-left: 10%;
    padding-right: 10%;
  }

  p {
    font-size: 20px;
  }
}

.homepageContent__Title {
  margin-bottom: 20px;
  font-size: 26px;
  line-height: 1.3;

  @media screen and (max-width: $mobile) {
    margin-bottom: 25px;
  }
}

.homepageContent__Title--Large {
  margin-bottom: 0;
  font-size: 70px;
  line-height: 1.15;
  font-weight: 300;

  @media screen and (max-width: $desktop) {
    font-size: 60px;
  }

  @media screen and (max-width: $tablet) {
    font-size: 50px;
  }

  @media screen and (max-width: $mobile) {
    font-size: 32px;
  }
}

.homepageContent__TitleWrapper {
  margin-right: 30px;
  padding-right: 30px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
    padding-right: 0;
  }
}

.homepageContent__TitleWrapper,
.homepageContent__Text {
  max-width: 559px;
  width: 50%;

  @media screen and (min-width: 1441px) {
    max-width: none;
  }

  @media screen and (max-width: $tablet) {
    width: 100%;
    max-width: none;
  }
}

.homepage__MoreInfoLink {
  position: relative;
  margin-top: 33px;
  font-size: 20px;
  text-transform: uppercase;
}

.homepageHeader {
  position: relative;

  img {
    width: 100%;
  }
}

.homepageHeader__FixedElementWrapper {
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;

  &.hidden {
    display: none;
  }
}

.homepageHeader__Fixed {
  position: sticky;
  top: calc(100vh - 80px);
  left: 0;
  padding-bottom: 50px;

  @media screen and (max-width: $mobile) {
    padding-bottom: 20px;
  }

  img {
    width: 200px;

    @media screen and (max-width: $mobile) {
      width: 100px;
    }
  }
}

.homepageHeader__Image--Desktop {
  display: block;

  @media screen and (max-width: $mobile) {
    display: none;
  }
}

.homepageHeader__Image--Mobile {
  display: none;

  @media screen and (max-width: $mobile) {
    display: block;
  }
}

.homepageHeader--Video,
.homepageHeader--VideoMobile {
  position: relative;
  height: 0;

  .video-header-image-wrapper,
  .video-header-mobile-image-wrapper {
    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 450;
      transform: translate(-50%, -50%);

      @media screen and (max-width: $mobile) {
        width: 70px;
        height: 70px;
      }
    }

    .video-header-image-hidden,
    .video-header-mobile-image-hidden {
      display: block;
    }

    .hideElement {
      display: none;
    }
  }
}

.homepageHeader--Video {
  padding-bottom: 56.25%;

  &.homepageHeader--WithMobileVideo {
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }
}

.homepageHeader--VideoMobile {
  display: none;
  padding-bottom: 177%;

  @media screen and (max-width: $mobile) {
    display: block;
  }
}

.homepageIntro__ContentWrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1186px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;

  @media screen and (min-width: 1441px) {
    max-width: 1480px;
  }

  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }
}

.homepageIntro {
  margin-top: 90px;
  margin-bottom: 120px;

  @media screen and (max-width: $tablet) {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .homepageContent__Text {
    margin-top: 30px;
    font-size: 20px;
  }
}

.homepageOurProducts {
  margin-top: 120px;
  margin-bottom: 125px;

  @media screen and (max-width: $tablet) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.homepageOurProducts__Listing {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  @media screen and (max-width: $mobile) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.homepageOurProducts__Item {
  position: relative;
  width: 100%;

  &:hover {
    .homepageOurProducts__ItemImage--Hover {
      @media screen and (hover: hover) {
        visibility: visible;
        opacity: 1;
      }
    }

    .underliner {
      transform: scale(1);
    }
  }

  img {
    width: 100%;
  }

  p {
    display: block;
    margin-top: 20px;
    font-size: 20px;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0;
    z-index: 10;
  }
}

.homepageOurProducts__ItemImage--Hover {
  position: absolute;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s linear;
}

.homepageFocus__Image--Desktop,
.homepageOurProjects__Image--Desktop {
  display: block;

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.homepageFocus__Image--Mobile,
.homepageOurProjects__Image--Mobile {
  display: none;

  @media screen and (max-width: 1000px) {
    display: block;
  }
}

.homepageFocus {
  margin-top: 125px;
  margin-bottom: 115px;
}

.homepageFocus__InnerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // max-width: calc(50% + 593px); // (1146 / 2 + 20px) 20px is for right padding

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.homepageFocus__Image {
  width: 55%;
  flex-grow: 1;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
}

.homepageFocus__Texts,
.homepageOurProjects__Texts,
.homepageStories__Texts {
  h2 {
    margin-bottom: 50px;

    @media screen and (max-width: 1000px) {
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 20px;
  }
}

.homepageFocus__Texts {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;

  @media screen and (max-width: 1000px) {
    margin-top: 30px;
    width: 100%;
  }
}

.homepageFocus__TextsInner {
  max-width: calc(500px + 24% + 60px);
  padding: 20px 24% 20px 60px;

  @media screen and (min-width: 1441px) {
    max-width: 540px;
    padding: 20px;
  }

  @media screen and (max-width: 1000px) {
    max-width: none;
    padding: 0 20px;
  }
}

.homepageSearchByCollections {
  margin-top: 115px;
  margin-bottom: 125px;

  @media screen and (max-width: $tablet) {
    margin-top: 50px;
    margin-bottom: 45px;
  }
}

.homepageSearchByCollections__List {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}

.homepageSearchByCollections__Item {
  position: relative;

  &:hover .underliner {
    transform: scaleX(1);
  }

  img {
    width: 100%;
  }

  p {
    position: relative;
    margin-top: 20px;
    font-size: 20px;

    @media screen and (max-width: $mobile) {
      margin-top: 15px;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0;
  }
}

.homepageOurProjects {
  display: flex;
  justify-content: flex-end;
  background-color: #c1c6bf;
}

.homepageOurProjects__InnerWrapper {
  display: flex;
  align-items: center;
  // width: calc(50% + 593px);

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.homepageOurProjects__Texts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 56%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.homepageOurProjects__TextsInner {
  max-width: calc(600px + 17% + 70px);
  padding: 20px 70px 20px 17%;

  @media screen and (min-width: 1441px) {
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: 1000px) {
    max-width: none;
    padding: 40px 20px;
  }
}

.homepageOurProjects__Image {
  width: 44%;
  flex-grow: 1;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
}

.homepageStories {
  margin-top: 105px;
  margin-bottom: 124px;

  @media screen and (max-width: $tablet) {
    margin-top: 45px;
    margin-bottom: 55px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.homepageStories__ContentWrapper {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $tablet) {
    flex-direction: column-reverse;
  }
}

.homepageStories__ImagesWrapper {
  display: flex;
  flex: 1;
  padding-left: 10%;

  @media screen and (max-width: $tablet) {
    margin-top: 45px;
    padding: 0 20px;
  }

  img {
    width: calc(50% - 20px);
    align-self: flex-start;
    margin-top: 30px;

    @media screen and (max-width: $tablet) {
      margin-top: 0;
    }

    @media screen and (max-width: $mobile) {
      width: calc(50% - 10px);
    }

    & + img {
      margin-left: 40px;

      @media screen and (max-width: $mobile) {
        margin-left: 20px;
      }
    }

    &:last-child {
      margin-top: 110px;

      @media screen and (max-width: $tablet) {
        margin-top: 0;
      }
    }
  }
}

.homepageStories__Texts {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;

  @media screen and (max-width: $tablet) {
    width: 100%;
  }
}

.homepageStories__TextsInner {
  max-width: 630px;
  padding: 0 40px;

  @media screen and (max-width: $tablet) {
    max-width: none;
    padding: 0 20px;
  }

  p {
    width: 80%;

    @media screen and (max-width: $tablet) {
      width: 100%;
    }
  }
}

.homepageDealers {
  display: none !important;
  position: relative;
  display: flex;
  justify-content: flex-end;
  background-color: $grey4;

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0;
  }
}

.homepageDealers__InnerWrapper {
  display: flex;

  @media screen and (min-width: 1441px) {
    align-items: center;
  }

  @media screen and (max-width: $tablet) {
    flex-direction: column;
    width: 100%;
    padding-left: 0;
  }

  h2 {
    max-width: 430px;
    margin-right: 30px;

    @media screen and (max-width: $tablet) {
      max-width: none;
      margin-right: 0;
    }
  }

  img {
    width: 100%;
  }
}

.homepageDealers__TitleWrapper {
  width: 48%;
  padding: 65px 60px 65px 10%;

  @media screen and (max-width: $tablet) {
    width: 100%;
    padding: 40px 20px 45px;
  }
}

.homepageDealers__ImagesWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 52%;
}

.homepageDealers__Image--Desktop {
  display: block;
  
  @media screen and (max-width: $tablet) {
    display: none;
  }
}

.homepageDealers__Image--Mobile {
  display: none;

  @media screen and (max-width: $tablet) {
    display: block;
  }
}