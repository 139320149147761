.search-form {
  position: relative;
  display: flex;
  align-items: baseline;
  margin: auto;
  max-width: colw(8);
}

.input-sizer {
  @include clamp('font-size', 24px, 52px, 480px, colw(8));
  font-weight: 300;
  visibility: hidden;
  margin-right: 24px;
  white-space: nowrap;
}

.search-input-holder {
  @include divfill;
}

.search-input {
  @include clamp('font-size', 24px, 52px, 480px, colw(8));
  min-height: 44px;
  font-weight: 300;
  height: 100%;
  width: 100%;

  &::placeholder {
    color: $grey1;
    font-weight: 300;
  }
}

.search-btn {
  flex-shrink: 0;
  width: 45px;
  height: 45px;
  text-align: center;

  > .search {
    margin: auto;
  }
}

.no-results{
  display: flex;
  flex-wrap: wrap;

  h2{
    margin-right: 50px;
  }
}

.item-search {
  @include col(1, 2);
  @include break-prop(margin-bottom, 20px, 30px);

  @include breakpoint($M) {
    @include col(1, 6);
  }

  h3 {
    font-size: 16px;
    text-align: left;
    line-height: 1.5;
    margin-top: 10px;
  }

  .news-date {
    color: $grey2;
    padding-top: 0;
    margin-top: 10px;
  }
}

.count {
  font-weight: 400;
}
