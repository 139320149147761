.market-landing {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    position: relative;
    >.container {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }
}

.market-landing-grid {
    display: flex;
    margin: auto;
    width: 100%;
    flex-wrap: wrap;
}

.market-landing-item {
    @include breakpoint(max-width 480px) {
        @include col(1, 1);
        &:first-of-type {
            margin-bottom: 30px;
        }
    }
    text-align: center;
    .underliner {
        transform-origin: center;
    }
    h2 {
        font-size: 46px;
        margin-bottom: 0.5em;
        line-height: 1;
    }
    p {
        font-size: 26px;
        line-height: 1;
    }
    @include breakpoint(min-width 480px) {
        // re-aligning: 1st to far left, 2nd centered and 3rd to far right
        &:first-of-type {
          // @include col(1, 4);
          @include col(1, 2);
            // text-align: left; // margin-left: 0;
            text-align: center; // margin-left: 0;
            .underliner {
                // text-align: left;
                text-align: center;
                // right: auto;
                margin-left: -15px;
            }
            h2 {
                margin-left: -15px;
            }
        }
        // &:nth-of-type(2) {
        //   @include col(1, 2);
        // }
        &:last-of-type {
          // @include col(1, 4);
          @include col(1, 2);
            // text-align: right; // margin-right: 0;
            text-align: center; // margin-right: 0;
            .underliner {
                // text-align: right;
                text-align: center;
                // left: auto;
                margin-right: -15px;
            }
            h2 {
                margin-right: -15px;
            }
        }
    }
}

.outlet-product-meta {
    text-align: center;
    span {
        white-space: nowrap;
        margin: 0 5px;
    }
}

.modal-title {
    @include clamp(font-size, 34px, 52px, 480px, colw(8));
    text-align: center;
    margin-bottom: 1em;
}

.outside-link {
    margin-top: 1.5em;
    margin-bottom: 40px;
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    align-items: baseline;
    .external {
        margin-left: 7px;
    }
}

.modal-small-caps {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
    margin-bottom: 1em;
}

.modal-info-col {
    @include col(1, 1);
    margin-top: 30px;
    margin-bottom: 30px;
    @include breakpoint($M) {
        @include col(1, 2);
    }
    .rich-copy {
        margin-bottom: 30px;
    }
}

.modal-info {
    .rich-copy {
        margin-bottom: 30px;
    }
}

.market-contact-title {
    font-size: 40px;
    margin-top: 2em;
}

.filter-select {
    display: table;
    margin-top: 30px;
    margin-bottom: 30px;
    min-width: colw(2);
    &.visible {
        display: table !important;
    }
    @include breakpoint($M) {
        display: none;
    }
}

.filter-inline-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
    @include breakpoint($S) {
        display: none;
    }
    button {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        color: $grey2;
        &:hover,
        &.active {
            color: $black;
        }
    }
}

// Promo banner - empty page
.promo-banner {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.corona-class {
    font-weight: bold;
}
