.readyToShip{
  background: url('../assets/rts-vizual.png') no-repeat center center;
  background-size: cover;
  height: auto !important;
}


.readyToShip__Content{
  max-width: 654px;
  margin: 67px auto 0 auto;
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: 768px) {
    margin: 40px 5% 0 5%;
  }

  h1{
    margin-bottom: 31px;
    text-align: center;
    font-size: 72px;
    font-weight: 300;
    line-height: 1.19;
    text-align: center;
  
    @media screen and (max-width: 768px) {
      margin-bottom: 41px;
      font-size: 36px;
      line-height: 1.17;
    }
  }

  p{
    font-size: 16px;
    line-height: 1.75;
    margin-bottom: 56px;

    @media screen and (max-width: 768px) {
      margin-bottom: 38px;
    }

    &:first-of-type{
      font-size: 21px;
      line-height: 1.43;
      text-align: center;
      margin-bottom: 40px;
    
      @media screen and (max-width: 768px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.75;
        text-align: left;
        margin-bottom: 27px;
      }
    }
  }

  div{
    display: flex;
    flex-direction: column;
  }
}

.readyToShip__Content--Button{
  align-self: center;
  margin-bottom: 62px;

  @media screen and (max-width: 768px) {
    margin-bottom: 46px;
  }
}


.readyToShip__Content--Card{
  align-self: center;
  width: 245px;
  background-color: $white;
  display: flex;
  margin-bottom: 47px;

  @media screen and (max-width: 768px) {
    margin-bottom: 58px;
  }
}

.readyToShip__Content--CardInner{
  max-width: 194px;
  margin: 27px auto 24px auto;
  display: flex;
  flex-direction: column;
}

.readyToShip__Content--CardImage{
  position: relative;
  align-self: center;
  margin-bottom: 30px;
}

.readyToShip__Content--CardImageDownloadIcon{
  position: absolute;
  bottom: 0;
  right: 0;
}

.readyToShip__Content--DocumentTitle{
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  align-self: center;
  margin-bottom: 4px;
  max-width: 194px;
  text-transform: capitalize;
}

.readyToShip__Content--DocumentTypeAndSize{
  font-size: 15px;
  line-height: 1.6;
  max-width: 194px;
  color: #8c8c8c;
}