.floating-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 500;
  will-change: transform;

  &.js-menu-opened {
    @include breakpoint($S) {
      display: none;
    }
  }
}

.nav-primary {
  display: flex;
  align-items: flex-end;
  padding-top: 20px;
}

.nav-primary-list {
  @include breakpoint($S) {
    display: none !important;
  }
  margin-left: auto;
  white-space: nowrap;

  > li {
    position: relative;
  }
}

.has-dropdown {
  position: relative;

  &:hover {
    .nav-dropdown-list {
      opacity: 1;
      transform: none;
      visibility: visible;
      pointer-events: all;
    }
  }
}

.nav-dropdown-control {
  position: relative;
  z-index: 1;
  cursor: auto;

  &.turn-into-link{
    cursor: pointer;

    &:hover{
      opacity: 1;
    }
  }

  &:hover {
    opacity: 0.5;

    ~ .nav-dropdown-list {
      opacity: 1;
      transform: none;
      visibility: visible;
      pointer-events: all;
    }
  }
}

.nav-dropdown-list {
  position: absolute;
  white-space: nowrap;
  top: 100%;
  padding-top: 15px;
  padding-bottom: 5px;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms ease;
  opacity: 0;
  transform: translateY(-1.2em);
  visibility: hidden;
  pointer-events: none;
  width: auto;
  will-change: opacity, visibility;

  &:not(.last-dropdown) {
    left: 0;
  }

  &.last-dropdown {
    right: 0;
  }

  > li {
    display: table-cell;

    &:not(:last-of-type) {
      padding-right: 15px;
    }
  }
}

.page-logo {
  @include breakpoint($S) {
    width: 160px;
  }
  margin-right: 40px;
  transition: opacity 200ms ease;
  flex-shrink: 0;
  will-change: opacity;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }

  > .svg {
    fill: currentColor;
    transition: fill 200ms ease, color 200ms ease;
    stroke: none;
  }
}

.page-header-wrapper {
  display: flex;
  align-items: center;

  @include breakpoint($M) {
    padding-top: 40px;
    margin-bottom: 40px;
  }
}

.projects-title-header{
  margin-bottom: 56px;
  margin-top: 180px;

  @media screen and (max-width: 560px) {
    margin-top: 100px;
  }
}

.collection-title{
  margin-bottom: 41px;
}