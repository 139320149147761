.tablist {
  // display: flex;
  // justify-content: center;
  margin-right: auto;
  margin-left: auto;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;

  > * {
    display: inline-block;
  }
}

.tab {
  padding: 3px 7px;
  white-space: nowrap;
  color: $grey2;

  &[aria-selected='true'] {
    color: inherit;
  }

  &:hover {
    color: $grey3;
  }
}

.tabpanel-container {
  position: relative;
}

.tabpanel {
  margin-top: 40px;
  contain: layout;

  &[aria-hidden='true'] {
    display: none;
  }
}
