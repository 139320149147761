.btn {
  $fs: 16px;
  $h: 50px;
  overflow: hidden;
  padding: $h - ($fs * 2) $gutter;
  min-width: colw(2);
  height: $h;
  background-color: $black;
  color: $white;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-size: $fs;
  line-height: 1;
  cursor: pointer;
  user-select: none;

  @include aa;

  &[disabled] {
    pointer-events: none;
    background-color: $grey2;
  }

  &:hover {
    background-color: $grey3;
    text-decoration: none !important;
  }

  &:active {
    background-color: $grey2;
  }
}

.btn-cta {
  @include breakpoint(max-width 640px) {
    width: 100%;
  }

  @include breakpoint(min-width 641px) {
    width: 50%;
  }
}

.btn-center {
  display: table;
  margin: 30px auto;
  min-width: colw(4);
}

.btn-center-narrow {
  @include breakpoint(max-width 640px) {
    min-width: 290px;
  }
}

.btn-more {
  margin-left: auto;
  margin-right: $gutter / 2;
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    opacity: 0.7;
  }

  .plus {
    margin-left: 6px;
  }
}

.btn-inner {
  display: flex;
  align-items: baseline;
}

.btn-wide {
  width: 100%;
}

.file-input-btn {
  display: flex;
  text-transform: uppercase;
  font-weight: 700;

  > svg {
    margin-right: 15px;
  }
}

.btn-search {
  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}

.btn-mobile-menu {
  @include breakpoint($M) {
    display: none;
  }

  @include square(44px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  .menu {
    margin: auto;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}
