/* custom scrollbar */

@include breakpoint($L) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-corner:vertical {
    background-color: rgba($black, 0);
  }

  ::-webkit-scrollbar-track:vertical {
    background-color: rgba($black, 0);
  }

  ::-webkit-scrollbar-thumb:vertical {
    min-height: 10px;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 4px solid transparent;
    background-color: $black;
    background-clip: padding-box;

    &:hover {
      background-color: $grey3;
    }

    &:active {
      background-color: $grey2;
    }
  }
}
