:root {
  font-family: sans-serif;
}

html.fonts-loaded {
  font-family: 'Graphik Web', sans-serif;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../assets/fonts/Graphik-Regular-Web.woff2') format('woff2'), url('../assets/fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../assets/fonts/Graphik-Semibold-Web.woff2') format('woff2'), url('../assets/fonts/Graphik-Semibold-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('../assets/fonts/Graphik-Light-Web.woff2') format('woff2'), url('../assets/fonts/Graphik-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
