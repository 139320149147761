// GRID
$gutter: 30px;
$col: 68px;
$ncol: 12;

// GREYS
$white: #fff;
$grey0: #f5f5f5;
$grey1: #e0e0e0;
$grey2: #9e9e9e;
$grey3: #616161;
$grey4: #f6f6f6;
$black: #000;
$red: #d7191e;

// OTHER
$ease: cubic-bezier(0.4, 0, 0.2, 1);
$ease-out: cubic-bezier(0, 0, 0.2, 1);
$ease-in: cubic-bezier(0.4, 0, 1, 1);

// BREAKPOINTS
$S: max-width 899px;
$M: 900px;
$L: 960px;
$XL: colw(12);

$mobile: 600px;
$tablet: 768px;
$desktop: 1100px;