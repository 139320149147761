.page-footer {
  @include clamp(padding-top, 120px, 180px);
}

.page-footer-inner {
  padding-top: 60px;
  padding-bottom: 60px;
  // border-top: 1px solid $black;
}

.sitemap {
  @include breakpoint($S) {
    flex-direction: column;
  }
  margin-top: 30px;
  margin-bottom: 60px;
}

.sitemap-col {
  margin-top: 30px;

  @include breakpoint($L) {
    @include col(1, 6);
  }

  @include breakpoint($M $L) {
    @include col(1, 3);
  }

  @include breakpoint($S) {
    @include col(1, 1);
  }

  h4 {
    margin-bottom: 1.5em;
    color: $grey2;
  }

  ul + ul {
    margin-top: 30px;
  }

  li + li {
    margin-top: 10px;
  }

  a {
    display: flex;
    align-items: center;

    .eu {
      margin-left: 8px;
      flex-shrink: 0;
    }
  }

  button {
    text-align: left;
  }
}

.page-footer-logo-row {
  display: flex;
  width: 100%;
}

.scroll-top-btn {
  margin-left: auto;

  &:hover {
    opacity: 0.8;
  }
}
