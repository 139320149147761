/*

Font size / line height variants

13/16
13/18

16/24 (light + heavy)
16/30

26/34 (light + heavy)

72/86

*/

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
}

.h1 {
  @include clamp(font-size, 34px, 72px, 480px, colw(8));
  position: relative;
  margin: 0 auto;
  max-width: colw(10);
  text-align: center;
  font-weight: 300;
  line-height: 1.2;
  width: 100%;

  &.small-h1 {
    @include clamp(font-size, 34px, 48px, 480px, colw(8));
  }
}

.h2 {
  position: relative;
  margin-top: 10px;
  text-align: left;
  font-size: 26px;
  line-height: (34 / 26);
  font-weight: 300;

  &:first-child {
    margin-top: 0;
  }
}

.career--new {
  .h2 {
    margin-top: 40px;
    font-weight: 400;
  }
}

// h2:not([class]) {
//   margin-top: 40px;
//   margin-bottom: 20px;
//   text-align: left;
//   font-size: 26px;
//   line-height: (34 / 26);
//   font-weight: 300;
// }

.h3 {
  position: relative;
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.2;

  &:first-child {
    margin-top: 0;
  }
}

.subtitle {
  margin-bottom: 0;
  text-align: center;
  font-weight: 400;
  line-height: (34 / 26);

  &:not(.small) {
    @include clamp(font-size, 16px, 26px, 480px, colw(8));
    margin-top: 0.35em;
  }

  &.small {
    margin-top: 1em;
    font-size: 16px;
  }

  &.subtitle-thank-you{
    font-size: 20px !important;
    margin-bottom: 35px;
    line-height: 1.6 !important;

    @media screen and (max-width: 768px){
      font-size: 16px !important;
    }
  }
}

.small-caps {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
}
