.flickity-viewport {
  min-width: 320px;
}

.carousel-single {
  .flickity-viewport {
    @include ratio(4, 3);
  }

  @include breakpoint($L) {
    .flickity-prev-next-button {
      &.next {
        right: -40px;
      }

      &.previous {
        left: -40px;
      }
    }
  }
}

.flickity-prev-next-button {
  @include breakpoint($S) {
    // display: none;
  }
  background: none;
  transition: opacity 200ms ease, color 200ms ease;
  user-select: none;

  // &.next {
  //   @include clamp(right, 25px, -200px, 960px, 1920px);
  // }

  // &.previous {
  //   @include clamp(left, 25px, -200px, 960px, 1920px);
  // }

  &:disabled {
    opacity: 0;
  }

  &:hover {
    background: none;
  }

  svg {
    @include square(44px);
    @include divfill;

    .arrow {
      fill: currentColor;
    }
  }
}

.flickity-page-dots {
  bottom: 25px;
  pointer-events: none;
  will-change: transform;
  user-select: none;

  > .dot {
    position: relative;
    margin: 0 4px;
    width: 2em;
    height: 2px;
    border-radius: 0;
    background-color: $black;
    transition: opacity 200ms ease, background-color 200ms ease;
    pointer-events: all;
    will-change: opacity;

    &::before {
      position: absolute;
      top: -4px;
      right: 0;
      bottom: -4px;
      left: 0;
      content: '';
    }
  }
}

.carousel-wide {
  @include breakpoint($S) {
    @include ratio(4, 3);
    max-height: 640px;
  }

  @include breakpoint($M) {
    min-height: 420px;
    height: 100vh;
  }
  position: relative;
  z-index: 1;
  text-align: center;
  user-select: none;

  .flickity-viewport {
    @include divfill;
  }

  .flickity-prev-next-button {
    @include breakpoint($S) {
      display: none;
    }
  }

  &.white-copy-slide {
    .dot {
      background-color: $white;
    }

    .flickity-prev-next-button {
      color: $white;
    }
  }
}

.flickity-slider {
  top: 0;
  left: 0;

  img {
    user-select: none;
    // pointer-events: none;
  }
}

.carousel-wide-cell {
  @include breakpoint($S) {
    padding-top: 60px;
  }
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $grey0;
  contain: strict;

  &:first-of-type {
    z-index: 10;
  }
}

.fp-cell-title-wrap {
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;

  > a {
    // display: flex;
  }

  @include breakpoint($M) {
    padding-top: 150px;
    padding-bottom: 90px;
  }
}

.career .fp-cell-title-wrap > .u-block {
  height: auto;
  pointer-events: none;
}

.fp-cell-title-wrap > a.link-career-a {
  display: none;

  .career & {
    display: flex;
    height: auto;
    margin-top: 40px;
    color: #fff;
    line-height: 1.31;

    span{
      &::first-letter{
        text-transform: uppercase !important;
      }
    }


    @include clamp(font-size, 15px, 26px);
    @include breakpoint($S) {
      text-align: center;
      display: block;
    }
  }
}

.fp-cell-title {
  @include clamp(font-size, 34px, 72px);
  position: relative;
  width: percentage(2 / 3);
  font-weight: 300;
  line-height: 1.2;
  will-change: transform;

  &::first-letter{
    text-transform: uppercase;
  }

  @include breakpoint($S) {
    width: auto;
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint($M) {
    text-align: initial;

    &.tl {
      text-align: left;
    }

    &.bl {
      margin-top: auto;
      text-align: left;
    }

    &.mid {
      text-align: center;
      margin: auto;
      transform: translateY(-30px);

      .underliner {
        transform-origin: center;
      }
    }

    &.tr {
      text-align: right;
      margin-left: auto;

      .underliner {
        transform-origin: right;
      }
    }

    &.br {
      text-align: right;
      margin-left: auto;
      margin-top: auto;

      .underliner {
        transform-origin: right;
      }
    }
  }
}

// horror

.carousel-cropped-sizer {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: 900px;
  width: 80vw;
  visibility: hidden;

  &::before {
    padding-bottom: percentage(2 / 3);
    width: 100%;
    content: '';
  }
}

.carousel-lazy-img {
  margin-right: 20px;
  height: 100%;
  transition: opacity 200ms ease;
  opacity: 0.35;

  &.is-selected {
    opacity: 1;
  }
}

.carousel-cropped {
  max-height: 600px;
  position: relative;
  overflow: hidden;
  contain: content;

  opacity: 0;
  transition: opacity 200ms ease;

  &.flickity-enabled {
    opacity: 1;
  }

  .flickity-viewport {
    @include divfill;

    &:not(.is-pointer-down) {
      .carousel-lazy-img {
        &:not(.is-selected) {
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.carousel-counter {
  margin-left: auto;
  margin-right: auto;
  display: table;
  margin-top: $gutter;
  margin-bottom: $gutter;
  text-align: center;
}

.carousel-single-cell {
  margin-right: 30px;
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;

  &:first-of-type {
    z-index: 10;
  }
}

.carousel-grouped {
  @include break-prop(margin-left margin-right, -10px, -15px);
  user-select: none;
  position: relative;
}

.carousel-grouped-cell {
  @include break-prop(padding-left padding-right, 10px, 15px);

  @include breakpoint($S) {
    min-width: colw(2);
    width: 40%;
  }
  width: percentage(1 / 3);
}

.other-news-container{
  background-color: $grey4;
  padding-top: 125px;
  padding-bottom: 65px;

  @include breakpoint($S) {
    padding-top: 65px;
    padding-bottom: 35px;
  }

  .h2,
  .h2-project{
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    color: $black;
    margin-top: 24px;
  }

  .h2-project{
    margin-top: 0;
  }

  .news-excerpt{
    margin-top: 27px;

    p{
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      color: $black;
    }
  }

  .news-date{
    padding-top: 36px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal
  }

  .item-project-location{
    text-transform: uppercase;
    margin-top: 34px;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.carousel-related-title,
.carousel-other-news-title,
.interview-with-designer-text{
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 900;
  color: $black;
}

.carousel-related {
  @include break-prop(margin-left margin-right, -10px, -15px);
  user-select: none;
  position: relative;

  .flickity-prev-next-button {
    &.next {
      right: -10px;
    }

    &.previous {
      left: -10px;
    }
  }
}

.carousel-related-cell {
  @include break-prop(padding-left padding-right, 10px, 15px);

  @include breakpoint($S) {
    min-width: colw(2);
    width: 40%;
  }
  width: percentage(1 / 3);

  .carosuel-related-cell-type{
    font-size: 12px;
    font-weight: 600;
    margin-top: 37px;
    display: flex;
    text-transform: uppercase;
  }

  h3{
    text-align: left;
    font-size: 24px;
    line-height: normal;
    color: #000000;
    margin-top: 13px;

    &:first-letter{
      text-transform: uppercase;
    }
  }
}

.product-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.carousel-single-wrap {
  @include clamp(margin-top, 40px, 90px, 480px, colw(8));
  position: relative;
}


.carousel.is-fullscreen .carousel-cell {
  height: 100%;
}

.carousel-cell img {
  display: block;
  max-height: 100%;
}

/* Flickity fullscreen v1.0.1
------------------------- */

.flickity-enabled.is-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 1);
  padding-bottom: 35px;
  z-index: 1;
  max-height: 100%;

  .flickity-slider{
    display: flex;
    align-items: center;
  }

  img{
    height: auto;
    max-width: 100%;
  }
}

.flickity-enabled.is-fullscreen .flickity-page-dots {
  bottom: 10px;
}

.flickity-enabled.is-fullscreen .flickity-page-dots .dot {
  background: white;
}

/* prevent page scrolling when flickity is fullscreen */
html.is-flickity-fullscreen {
  overflow: hidden;
  opacity: 1;
}

/* ---- flickity-fullscreen-button ---- */

.flickity-fullscreen-button {
  display: none;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

/* right-to-left */
.flickity-rtl .flickity-fullscreen-button {
  right: auto;
  left: 10px;
}


.flickity-fullscreen-button-exit { display: none; }

.flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit { display: none; }
.flickity-enabled.is-fullscreen .flickity-fullscreen-button-view { display: none; }

.flickity-fullscreen-button .flickity-button-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 4px;
  top: 4px;
}